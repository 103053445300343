import React from "react"
import { Link } from "gatsby"
import Layout from "../elements/Layout"
import Helmet from "../elements/Helmet"
import Header from "../components/Header"
import PageHdr from "../components/PageHdr"
import Footer from "../components/Footer"
import { Main, MainContainer } from "../components/content/Accessibility/styled"

const Accessibility = () => {
  return (
    <Layout>
      <Helmet
        name="Accessibility Statement"
        description="Read our statement on how Mechanical Air Conditioning’s web site applies relevant accessibility standards."
        slug="accessibility"
      />
      <Header />
      <PageHdr title="Accessibility Statement" />
      <section>
        <Main>
          <MainContainer className="main-page">
            <article>
              <p>
                Mechanical Air Conditioning is committed to ensuring digital
                accessibility for people with disabilities. We are continually
                improving the user experience for everyone, and applying the
                relevant accessibility standards.
              </p>

              <p>
                The Web Content Accessibility Guidelines (WCAG) defines
                requirements for designers and developers to improve
                accessibility for people with disabilities. It defines three
                levels of conformance: Level A, Level AA, and Level AAA. Our web
                site is partially conformant with WCAG 2.1 level AA. Partially
                conformant means that some parts of the content do not fully
                conform to the accessibility standard.
              </p>

              <p>
                We welcome your feedback on the accessibility of
                shop.mechanicalac.com web site, please let us know if you
                encounter accessibility barriers:
              </p>

              <p>
                Phone: (561) 848-6227
                <br />
                Email: <Link to="/contact">Send us a Message</Link>
                <br />
                Mail: 1373 N Killian Drive, Lake Park, FL 33403
              </p>

              <p>We try to respond to feedback within 5 business days.</p>
            </article>
          </MainContainer>
        </Main>
      </section>
      <Footer />
    </Layout>
  )
}

export default Accessibility
